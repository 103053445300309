import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnChanges,
} from '@angular/core';
import {LinktreeBtnConfig} from '../../linktree';
import {Link} from '../../../../shared/link/link';

@Component({
    selector: 'linktree-page-button',
    templateUrl: './linktree-page-button.component.html',
    styleUrls: ['./linktree-page-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinktreePageButtonComponent implements OnChanges {
    @Input() link: Link;
    @Input() style: LinktreeBtnConfig;

    @HostBinding('class.animate__animated') get hasAnimation() {
        return this.link?.animation;
    }

    @HostBinding('class.has-image') get hasImage() {
        return this.link?.image;
    }

    constructor(
        private cd: ChangeDetectorRef,
        private el: ElementRef<HTMLElement>
    ) {}

    ngOnChanges() {
        this.toggleAnimationClass();
        this.cd.markForCheck();
    }

    private toggleAnimationClass() {
        if (this.link.animation) {
            this.el.nativeElement.classList.add(...[
                `animate__${this.link.animation}`,
                'animate__repeat-1',
            ]);
        } else {
            const animClasses = Array.from(this.el.nativeElement.classList).filter(
                className => {
                    return className.startsWith('animate__');
                }
            );
            if (animClasses.length) {
                this.el.nativeElement.classList.remove(...animClasses);
            }
        }
    }
}
